import React from "react"
import { Link } from "gatsby"

// Components
import { PageMeta } from "../components/pageMeta"

const NotFoundPage = () => {
  return (
    <>
      <PageMeta
        metaTitle="404: Not Found"
        metaDescription="We can't seem to find the page you are looking for."
      />
      <div className="flex justify-center h-full items-center fof-wrap">
        <div className="text-center">
            <h1 className="text-h1 md:col-span-12 font-heading">404</h1>
            <h2 className="uppercase someday-serif md:col-span-12">
              We can't seem to find the page you are looking for.
            </h2>
            <Link
              to="/"
              className="uppercase md:col-span-12 someday-serif">
                Go back to home
            </Link>
          </div>
      </div>
    </>
  )
}

export default NotFoundPage
